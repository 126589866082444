html {
    overflow: hidden;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
}

div {
    margin: 0;
    padding: 0;
}


/* #bp3d-js-app {
    overflow: hidden;
} */

#bp3djs-viewer2d,
#bp3djs-viewer3d {
    position: absolute;
    top: 0;
    left: 0;
}

#bp3djs-controls {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

/**
    The below css enables color picker for quicksettings
    https://github.com/bit101/quicksettings/issues/57
*/
.qs_color {
    position: absolute;
    left: 5px; 
    visibility: hidden; 
  }

.viewer3d-measurement-labels {
    color: #FFF;
    font-family: sans-serif;
    padding: 2px;
    background: rgba( 0, 0, 0, .6 );
}